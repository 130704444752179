<template>
  <DetailTemplate
    :customClass="'expense-detail detail-page'"
    v-if="getPermission('expense:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ expenseTitle }}
          </h1>
          <CustomStatus
            :status.sync="expenseDetail.status"
            endpoint="expenses/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="expenseDetail.status != 3">
        <v-btn
          :disabled="pageLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="updateMoreAction('edit')"
        >
          Edit <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-bold-button white--text"
        color="red lighted-1"
        v-on:click="updateMoreAction('delete')"
      >
        Delete <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <template v-if="moreActions.length">
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="6">
            <table width="100%">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Expense Subject
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ expenseDetail.title }}
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Project #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template
                    v-if="lodash.isEmpty(expenseDetail.project) == false"
                    >{{ expenseDetail.project.barcode }}</template
                  >
                  <template v-else
                    ><em class="text--secondary">No Project #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Project Name
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template
                    v-if="lodash.isEmpty(expenseDetail.project) == false"
                    >{{ expenseDetail.project.name }}</template
                  >
                  <template v-else
                    ><em class="text--secondary">No Project Name</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Customer #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template
                    v-if="lodash.isEmpty(expenseDetail.customer) == false"
                    >{{ expenseDetail.customer.barcode }}</template
                  >
                  <template v-else
                    ><em class="text--secondary">No Customer #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Customer Company
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template
                    v-if="lodash.isEmpty(expenseDetail.customer) == false"
                    >{{ expenseDetail.customer.company_name }}</template
                  >
                  <template v-else
                    ><em class="text--secondary"
                      >No Customer Company</em
                    ></template
                  >
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="6">
            <table width="100%">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Expense #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ expenseDetail.barcode }}
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Reference #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="expenseDetail.reference">{{
                    expenseDetail.reference
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Reference #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Expense Category
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="expenseDetail.expense_category">{{
                    expenseDetail.expense_category
                  }}</template>
                  <template v-else
                    ><em class="text--secondary"
                      >No Expense Category</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Expense Amount
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="expenseDetail.amount"
                    >{{
                      $accountingJS.formatMoney(expenseDetail.amount)
                    }}
                    (<template v-if="expenseDetail.payment_mode">{{
                      expenseDetail.payment_mode.text
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Payment Mode
                      </em></template
                    >)</template
                  >
                  <template v-else
                    ><em class="text--secondary"
                      >No Expense Amount
                    </em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Expense Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="expenseDetail.expense_date">{{
                    formatDate(expenseDetail.expense_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Expense Date</em></template
                  >
                </td>
              </tr>
            </table>
          </v-col>
          <v-col
            md="12"
            class="py-0"
            content="Description"
            v-tippy="{ placement: 'top-start' }"
          >
            <read-more
              class="custom-read-more custom-border-grey-dashed font-weight-600 font-size-18 p-4 custom-gray-color"
              more-str="read more"
              :text="expenseDetail.description || '<em>No Description</em>'"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </v-col>
          <v-col md="12">
            <FileTemplate
              :allowDelete="false"
              :attachments.sync="attachments"
            ></FileTemplate>
          </v-col>
        </v-row>
      </v-container>
      <ExpenseDelete
        :deleteDialog.sync="expenseDeleteDialog"
        :requestUrl.sync="expenseDeleteRequestUrl"
        v-on:delete:success="expenseDeleteSuccess"
        v-on:delete:close="expenseDeleteDialog = false"
      ></ExpenseDelete>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import ExpenseDelete from "@/view/pages/partials/Delete-Request-Template.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "expense-detail",
  title: "Detail Expense",
  data() {
    return {
      expense: null,
      expenseDeleteDialog: false,
      attachments: new Array(),
      moreActions: new Array(),
      pageLoading: false,
      expenseDetail: new Object({
        barcode: null,
        reference: null,
        customer: null,
        project: null,
        title: null,
        amount: null,
        expense_category: null,
        payment_mode: null,
        description: null,
        expense_date: null,
        status: null,
        attachments: []
      })
    };
  },
  components: {
    DetailTemplate,
    CustomStatus,
    ExpenseDelete,
    FileTemplate
  },
  methods: {
    expenseDeleteSuccess() {
      this.expenseDeleteDialog = false;
      this.goBack();
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("expense.update", {
              params: {
                id: _this.expense
              }
            })
          );
          break;
        case "delete":
          _this.expenseDeleteDialog = true;
          break;
        case "mark_as_billable":
          _this.updateExpense({ status: 1 });
          break;
        case "mark_as_non_billable":
          _this.updateExpense({ status: 2 });
          break;
        case "mark_as_billed":
          _this.updateExpense({ status: 3 });
          break;
      }
    },
    updateExpense(data) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, { url: "expenses/" + _this.expense, data })
        .then(() => {
          _this.getExpense();
        })
        .catch(error => {
          _this.logError(error);
        });
    },
    getExpense() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "expenses/" + _this.expense
        })
        .then(({ data }) => {
          _this.expenseDetail = new Object({
            barcode: data.barcode,
            reference: data.reference,
            customer: data.customer,
            project: data.project,
            title: data.title,
            amount: data.amount,
            expense_category: data.expense_category,
            payment_mode: data.payment_mode,
            description: data.description,
            expense_date: data.expense_date,
            status: data.status,
            attachments: new Array()
          });
          _this.moreActions = data.more_actions;
          _this.attachments = data.attachments;
        })
        .catch(error => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Expense", route: "expense" },
      { title: "Detail" }
    ]);

    _this.expense = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.expense <= 0) {
      _this.goBack();
    }

    _this.getExpense();
  },
  computed: {
    expenseDeleteRequestUrl() {
      return "expenses/" + this.expense;
    },
    expenseTitle() {
      return this.expenseDetail.title;
    }
  }
};
</script>
